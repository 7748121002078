import { InjectionToken } from '@angular/core';

export interface CustomConfig {
    policyURL: string;
    ArenderURL: any;
    getSessionURL: string;
    oktaLogin: boolean;
    appURL: string;
    tasksAPI: string;
    scripts: string[];
    irisApiKey: string;
    okta: {
        baseURL: string;
        clientId: string;
        issuer: string;
        scopes: string[];
        logo: string;
        pkce: boolean;
        redirectUri: string;
        settingsURL: string;
    };
}

export let CUSTOM_CONFIG = new InjectionToken<CustomConfig>('CUSTOM_CONFIG');
